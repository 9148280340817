import React from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import { RelatedPosts } from '../components/Related/RelatedPosts';
import { RenderShortcode } from '../components/RenderShortcode';
import { Image } from '../components/Image';
import './project.scss';
import { decodeEntities, stripHtmlTags } from '../utils/helpers';

const ProjectPost = ( props ) => {
  const { data, location } = props;
  const { wordpressPost: post, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings
  if (!post) return null;
  const { title, content, excerpt, featured_media, yoast, categories, acf = null } = post;
  return (
    <Layout wordpressUrl={wordpressUrl}>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={featured_media && featured_media.source_url && featured_media.source_url}
        location={location}
      />
      <PageHeader headerTitle={title} headerBackgroundImage={featured_media} location={location} />
      <section className="single-project">
        <div className="outer">
          <div className="wrap">
            <div className="inner">

                <div className="project-details">

                  {acf && acf.products &&
                  <section>
                    <h4>Products</h4>
                    {acf.products.map((product, index) =>
                      <GatsbyLink key={index} to={`${product.link}`}>{product.post_title}</GatsbyLink>
                    )}
                  </section>
                  }

                  {acf && acf.industry &&
                  <section>
                    <h4>Industry</h4>
                    {acf.industry.map((ind, index) =>
                      <GatsbyLink key={index} to={ind.link}>{ind.name}</GatsbyLink>
                    )}
                  </section>
                  }

                  {acf && acf.specialRequirements &&
                  <section>
                    <h4>Special Requirements</h4>
                    <RenderShortcode content={acf.specialRequirements} />
                  </section>
                  }

                </div>

                <RenderShortcode content={content} />

            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Previewable(ProjectPost, 'projects');

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
        }
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ... GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        industry {
          name
          slug
          link
        }
        products {
          link
          post_title
        }
        specialRequirements
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`
